

.owl-carousel{
  //min-height: 600px; // removed for mobile ready fix by: Sal - 9-22-2016
  background: url("/images/slider/slider_bg_star.png") top center no-repeat;

.carousel-item{
  vertical-align: middle;
}

}

.carousel{
  display: flex;
  align-items: center;
  //min-height: 650px; // removed for mobile ready fix by: Sal - 9-22-2016
}