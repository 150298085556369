

// Footer Colors

$footer-bg-color: #413F41;
$footer-border: #ED3337;
$footer-loc-num: #B4AB59;


// top bar Menu
$top-nav-bg: rgb(65,63,65);

// Add-on
$add-on-bg: rgb(241,49,56);

// Main Colors
$core-red:        #ED3337;
$core-lite-black: #413F41;
$core-nav-text:   #EEEEEE;
$core-gold-bg: #B4AB59;
$core-eclub-text: rgb(152, 152, 152);