.lunch-combo-item-wrapper-pages {
  min-height: 188px;
  border: 3px solid $add-on-bg;
  margin-top: 30px;

  .lunch-combo-item-title-pages {
    text-align: center;
    font-size: 30px;
    font-family: Conv_Artbrush;
    line-height: 30px;
    letter-spacing: 1px;
    background: #FFFFFF;
    color: $footer-bg-color;
    margin-top: -20px;
    max-width: 305px;
    margin-left: auto;
    margin-right: auto;
  }

  .lunch-combo-item-dis-pages{
    margin: 28px;
    text-align: center;
    font-family: 'Cabin', sans-serif;
    font-size: 25px;
    line-height: 40px;
  }

  .item-price-pages{
    font-family: Freshman;
    font-size: 50px;
    line-height: 80px;
  }

  .item-feeds{
    font-family: Freshman;
    font-size: 45px;
    color: $footer-border;
    line-height: 50px;
  }

  .item-pre-pages{
    color: rgba(237,51,55,0.8)
  }

}