.flip {
  -webkit-perspective: 800px;
  perspective: 800px;
  position: relative;
  //text-align: center;
}
.flip .card.flipped {
  -webkit-transform: rotatey(-180deg);
  -moz-transform: rotatey(-180deg);
  -o-transform: rotatey(-180deg);
  transform: rotatey(-180deg)
}
.flip .card {
  width: inherit;
  min-width: 100%;
  height: 400px; //374px
  -webkit-transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transition: 0.5s;
  background-color: #EEEEEE;

}
.flip .card .face {
  -webkit-backface-visibility: hidden ;
  backface-visibility: hidden ;
  -webkit-transform: rotatey(-0deg);
  transform: rotatey(0deg);
  z-index: 2;
}
.flip .card .front {
  position: absolute;
  width: inherit;
  min-width: 100%;
  z-index: 1;
}
.flip .card .img {
  position: relaitve;
  width: inherit;
  height: 178px;
  z-index: 1;
  border: 2px solid #000;
}
.flip .card .back {
  //padding-top: 10%;
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}
.inner{margin:0px !important;}

.no-flip .card {
  width: inherit;
  min-width: 100%;
  height: 400px; //374px
  -webkit-transform-style: preserve-3d;
  -webkit-transition: 0.5s;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transition: 0.5s;
  background-color: #EEEEEE;

}


.no-flip .card .front {
  position: absolute;
  width: inherit;
  min-width: 100%;
  z-index: 1;
}
.no-flip .card .img {
  position: relaitve;
  width: inherit;
  height: 178px;
  z-index: 1;
  border: 2px solid #000;
}