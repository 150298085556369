

.menu-nav-wrapper{
  min-height: 230px;

  .menu-nav-img-wrapper{
    min-height: 190px;
    margin-top: -5px;
  }

  .pizza{
    background: url("/images/page-henders/pizza_hender.png") center center no-repeat;
  }

  .dinner{
    background: url("/images/page-henders/dinner_hender.png") center center no-repeat;
  }

  .sides{
    background: url("/images/page-henders/sides_hender.png") center center no-repeat;
  }

  .salads{
    background: url("/images/page-henders/salads_hender.png") center center no-repeat;
  }

  .subs{
    background: url("/images/page-henders/subs_hender.png") center center no-repeat;
  }

  .beverage{
    background: url("/images/page-henders/beverage_hender.png") center center no-repeat;
  }

  .lunch{
       background: url("/images/page-henders/lunch_hender.png") center center no-repeat;
     }

  .catering{
    background: url("/images/page-henders/catering_hender.png") center center no-repeat;
  }

  nav{
    margin-top: -5px;
    border-bottom: 3px solid $footer-border;

  }

  a{
    text-align: center;
    color: $footer-border !important;
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    margin-top: 5px !important;
  }

  h1{
    padding: 120px 0 0 20px;
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 3.8em;
    text-shadow: 0 0 10px #222;
    color: #FFFFFF;
  }

  li{
    margin-left: 0rem !important;
  }


}
