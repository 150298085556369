/**
 * Created by ProSite Media Inc.
 * Developer: Salvatore Boettcher
 * Date/Time: 10/7/19 - 5:04 PM
 * Project: CarlosPizzaLive
 * IDE Program: PhpStorm
 */

.item-tag-inner-wrapper{
  border: 1px solid $core-lite-black;
  background-color: $core-nav-text;

  .item-tag-title-box{
      /* Extra small devices (phones, 600px and down) */
      @media only screen and (max-width: 600px) {
        width: 56%;


      }

      /* Small devices (portrait tablets and large phones, 600px and up) */
      @media only screen and (min-width: 600px) {
        width: 72%;
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 768px) {
        width: 72%;
      }

      /* Medium devices (landscape tablets, 768px and up) */
      @media only screen and (min-width: 800px) {
        width: 77%;
      }

      /* Large devices (laptops/desktops, 992px and up) */
      @media only screen and (min-width: 992px) {
        width: 64%;
      }

      /* Extra large devices (large laptops and desktops, 1200px and up) */
      @media only screen and (min-width: 1200px) {
        width: 64%;
      }

      font-size: 1rem;
      font-size: 1em !important;
      padding-left: 1rem;

      .item-title{
        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
          padding-right: 4px !important;
          padding-left: 18px !important;

        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px) {
          padding-right: 4px !important;
          padding-left: 18px !important;

        }

      }
    }

  .item-image{
    border-color: $core-red;
    border-width: 13px;
    margin-left: 8px;
    }

  .item-image-1{
    border-color: $core-gold-bg;
    height: 135px;
    width: 135px;
    border-width: 6px;

    }

  .item-image-2{
    border-color: $core-lite-black;
    height: 123px;
    width: 123px;
    border-width: 2px;
    background-color: $core-nav-text;

    }

  .item-tag-desc{
    padding-right: 10px;
    }
}
  .item-tag-price-wrapper{
    width: 100px;
    height: 100px;
    background: $footer-bg-color;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255, 255, 255, .1) 4px, rgba(255, 255, 255, .1) 5px);
    border-radius: 100px 0 0 0;

    .item-tag{
      //background: $footer-bg-color;
      width: 95px;
      height: 95px;
      margin-top: 5px;
      margin-left: 5px;
      border-radius: 100px 0 0 0;
      border-width: 2px 0 0 2px;
      border-color: $core-red;

      .item-tag-price{
        color: $core-nav-text;
        font-family: Lato;
        font-size: 22px;
        font-weight: bold;

      }


    }
  }

.item-tag-top-price{
  font-size: 15px;
  font-family: Lato;
}

.item-tag-top-title{
  font-family: Lato;
  font-size: 12px;
}

.item-tag-size{
  font-weight: bold;
}

.item-tag-r-s{
  font-size: 10px;
}

.item-tag-disclaimer{
  font-size: 10px;
}