.lunch-menu-item-wrapper{
  border: 5px solid #424042 ;
  margin-bottom: 35px;

  #lunch-title-text {
    font-size: 20px;
  }

  .title-all{
    margin-bottom: 0px !important;
  }

  .date-exp{
    font-family: 'Lato';
    font-size: 14px;
    color: $footer-bg-color;
  }

  #lunch-item-list-menu{
    font-size: 20px !important;
    padding-left: 5px !important;
    font-family: Conv_Artbrush;
    font-size: 25px !important;
    letter-spacing: 1px !important;
  }
}