// Extra small devices (portrait phones, less than 544px)
@media (max-width: 543px) {
    .container{
      padding-left: 0px !important;
      padding-right: 0px !important;


    }


  .pagination{
    display: block !important;
    margin-bottom: 2rem;
  }

  .lunch-item-logo{
    width: 250px !important;
    background-color: white;
    background-image: none;
    padding-bottom: 0px;
    margin-left: 29px !important;


    .lunch-item-title{
      font-size: 24px;
      line-height: 25px;
      top: -12px;

    }
  }

  .lunch-row{
    margin-top: 2rem;
  }

  .col-xs-12, .col-sm-12, .col-xs-10{
    padding-right: 0px !important;
    padding-left:  0px !important;
  }


  h1{
    font-size: 1.8em !important;
    padding-top: 130px !important;
  }

  .item-list-box{
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .item-title > button{
    color: #ffffff;
  }

  hr{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 770px) {

  .menu-nav-img-wrapper{
    h1{
      font-size: 1.8em;
      padding: 135px 0 0 20px;

    }
  }

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {



}

// Small Devices (Small Phones and Tables)
@media (max-width: 640px) {
  .item-pre-opt-title{
    font-size: 15px !important;
  }

}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {

}