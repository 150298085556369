
.add-on-wrapper {
  min-height: 280px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255, 255, 255, .1) 4px, rgba(255, 255, 255, .1) 5px);
  background-color: rgb(241, 49, 56);

  .add-on-title{
    font-family: 'Russo One', sans-serif;
    font-size: 45px;
    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 3px 3px 4px rgba(0,0,0,0.4);
    text-space: 5px;
  }

  .add-on-text{
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;


  }

  .add-on-limits{
     font-size: 14px;
     font-family: 'Cabin', sans-serif;
    padding-left: 12px;
    color: #FFFFFF;
    text-align: center;
   }

  img{
    display: block;
    margin: 0 auto;
  }
}

.lunch-special-wrapper{
  background: #EEEEEE;
  min-height: 250px;
  border-top: #424042 solid 5px;

  .lunch-special-title{
    min-height: 38px;
    font-family: Freshman;
    font-size: 35px;
    color: #EDA62A;
    background: url("/images/lunch-combos/lunch-special-bg.png") top center repeat-x;
    margin-top: -25px;
    text-align: center;

  }

  .lunch-combo-item-wrapper{
       min-height: 188px;
       width: 272px;
       border: 3px solid $add-on-bg;

    .lunch-combo-item-title{
      text-align: center;
      font-size: 22px;
      font-family: 'Russo One', sans-serif;
      background: #EEEEEE;
      color: $footer-bg-color;
      margin-top: -13px;
      max-width: 205px;
      margin-left: 34px;
      margin-right: 34px;
      letter-spacing: 1px;
      line-height: 22px;
    }

    .lunch-combo-item-dis-image{
      min-height: 200px;
      min-width: 200px;
      margin-top: 17px;
      margin-left: -12px;
    }

    .lunch-combo-item-dis{
      height: 182px;
      width: inherit;


      span{
        text-wrap: normal;
        position: absolute;
        left: 130px;
        font-family: Lato;
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        margin-top: 5px;

      }
    }

  }
}

.lunch-item-logo{
  padding-top: 32px;
  padding-bottom: 32px;
  background: url("/images/lunch-combos/lunch_plaque_bg.png") center center no-repeat;
  width: 370px;
  height: 47px;
  margin-top: -35px;
  margin-bottom: 15px;
  text-align: center;

  .lunch-item-title{
    font-family: 'Russo One', sans-serif;
    font-size: 32px;  // 40px
    line-height: 40px;
    position: relative;
    top: -17px;
    font-weight: bold;
    color: rgba(65,63,65,0.8);
    text-shadow: 3px 3px 4px rgba(255,255,255,0.4);
  }
}

.lunch-combo-wrapper{
  min-height: 200px;

}

  .lunch-item-list{
    list-style: none;
    font-size: 26px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
    color: #F13138;
    font-weight: normal;
    letter-spacing: 1px;

    span{
      color: $footer-bg-color;
      font-size: 16px;
    }
  }




