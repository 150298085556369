.e-club-wrapper{
  min-height: 200px;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255, 255, 255, .1) 4px, rgba(255, 255, 255, .1) 5px);
  background-color: $footer-border;
}

.e-club-title{
  font-family: 'Cabin', sans-serif;
  color: #ffffff;
  text-align: center;
  text-decoration: underline;
}

.e-club-intro-text{
  font-family: 'Lato', sans-serif;
  color: $core-lite-black;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.e-club-desc{
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  color: $core-lite-black;
}