
.logo{
  display: block;
  position: absolute;
  height: 40px;
  width: 84px;
  left: 40%;
  right: 50%;
}

.main-logo{
  z-index: 1000;
  background: url("/images/logos/menu_logo.png") center center no-repeat;
  min-height: 128px;
  width: 235px;
  display: block;
  position: absolute;
  top: -69px;

}

.logo-mobile{
  background-size: 100% auto;
  max-width: 120px;
  background: url("/images/logos/logo_mobile.png") center center no-repeat;
}

@-moz-document url-prefix() {
  .main-logo {
    top: -68px;
  }
}

//.navbar-nav .nav-item + .nav-item{
//  margin-left: 0rem !important;
//}

.top-nav-bar{
  min-height: 29px;
  background-color: rgb(65,63,65) !important;
}

.bottom-nav-bar{
  min-height: 31px;
  max-height: 31px;
  margin-top: 2px;
  background-color: #ED3337 !important;
}

.nav-item{
  margin-top: -12px;

}

.navbar-dark .navbar-nav .nav-link{
  color: $footer-bg-color !important;
  font-family: 'Cabin', sans-serif;
  font-weight: bold;
}

.nav-social {
  margin-top: 12px;
}

// Mobile Nav Menu SCSS Below

.bottom-nav-bar-mobile{
  background-color: #ED3337 !important;
}