.size-item-header{
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: $footer-border;
  font-weight: bold;
  text-align: center;
  display: block;

}

.item-round-dd{
  font-size: 12px;
  font-family: 'Cabin', sans-serif;
  text-align: center;
  color: $footer-bg-color;
  display: block;
}

.item-price{
  font-family: Lato;
  font-size: 15px;
  text-align: center;
  color: $footer-bg-color;
  display: block;
  font-weight: bold;
}

.item-inline{
  display: inline-block;
}

.item-sub-title{
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: $footer-border;
}

.item-opt{
  display: block;
  font-size: 14px;
  font-family: 'Cabin', sans-serif;
}

.item-pre-opt-title{
  display: inline-block;
  font-size: 18px;
  color: $footer-border;
  font-family: Conv_Artbrush;
  font-weight: 200;
  letter-spacing: 2px;

  a{
    color: $footer-border;
  }

  a:hover{
    color: $footer-border;
  }
}

.item-end-clear{
  margin-top: 30px;
}

.item-title-box {
  background: $footer-bg-color;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255, 255, 255, .1) 4px, rgba(255, 255, 255, .1) 5px);
  padding: 15px 0px;

  .item-title {
    line-height: 40px;
    padding: 0 20px;
    font-family: 'Cabin', sans-serif;
    color: #EEEEEE;
    text-transform: uppercase;
    //font-size: 1.3em;
    border-top: 2px solid $footer-border;
    border-bottom: 2px solid $footer-border;

    .item-spec-img {
      position: relative;
      top: -35px;
      left: 50%;
      max-width: 300px;
      max-height: 132px;

      img {
        transform: rotate(15deg);
      }
    }

  }
}
