@import "import";
@import "bootstrap/scss/bootstrap";

@import "variables";
@import "font-awesome/scss/font-awesome";



#app-layout{
  min-height: 800px;
}

.no-padding{
  padding: 0px ;
}

.no-padding-l-r{
  padding-left: 0px;
  padding-right: 0px;
}

.pagination{
  display: block !important;
}

#margin-top-15{
  margin-top: 15px;
}

#margin-bottom-2{
  margin-bottom: 2px;
}

.page-wrapper{
  margin-top: 50px;
}

section{
  min-height: 800px;
  margin-bottom: 50px;
}

.container-fluid{
  padding-left: 0px;
  padding-right: 0px;
}

.row{
  margin-left: 0px;
  margin-right: 0px;
}

.card{
  //min-height: 420px;
  margin-bottom: 30px !important;
  border: 1px solid $footer-border;

}

.card-deck .card{
  display: inline-block;
}

.box-bottom
{
  position:relative;
  -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.box-bottom:before, .box-bottom:after
{
  content:"";
  position:absolute;
  z-index:-1;
  -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
  -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
  box-shadow:0 0 20px rgba(0,0,0,0.8);
  top:50%;
  bottom:0;
  left:10px;
  right:10px;
  -moz-border-radius:100px / 10px;
  border-radius:100px / 10px;
}
.box-bottom:after
{
  right:10px;
  left:auto;
  -webkit-transform:skew(8deg) rotate(3deg);
  -moz-transform:skew(8deg) rotate(3deg);
  -ms-transform:skew(8deg) rotate(3deg);
  -o-transform:skew(8deg) rotate(3deg);
  transform:skew(8deg) rotate(3deg);
}

.box-bottom-card
{
  position:relative;
  -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.box-bottom-card:before, .box-bottom-card:after
{
  content:"";
  position:absolute;
  z-index:-1;
  -webkit-box-shadow:0 0 15px rgba(0,0,0,0.8);
  -moz-box-shadow:0 0 15px rgba(0,0,0,0.8);
  box-shadow:0 0 15px rgba(0,0,0,0.8);
  top:0;
  bottom:0;
  left:1px;
  right:1px;
  //-moz-border-radius:100px / 10px;
  //border-radius:100px / 10px;
}
.box-bottom-card:after
{
  right:10px;
  left:auto;
  -webkit-transform:skew(8deg) rotate(3deg);
  -moz-transform:skew(8deg) rotate(3deg);
  -ms-transform:skew(8deg) rotate(3deg);
  -o-transform:skew(8deg) rotate(3deg);
  transform:skew(8deg) rotate(3deg);
}

.deep-red.accent-2{
  background-color: $core-red;
}

