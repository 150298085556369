
.business-hours .opening-hours li.today {
  color: $core-gold-bg;
}

.today{
  color: $core-red;
  font-weight: bold;
}


.footer-copyright{
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  color: rgba(255,255,255,.6);
  background-color: rgba(0,0,0,.2);
  text-align: center;
  font-size: .9rem;
}
.title{
  margin-bottom: 1rem;
  text-transform: uppercase;

}
.font-bold{
  font-weight: 500;
}

.page-footer{
  background-color: $core-lite-black;
  color: $core-nav-text;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255,255,255,.1) 4px, rgba(255,255,255,.1) 5px);
  font-size: 0.9rem;
}
.close-btn{
  color: $core-red;
}

footer.page-footer a{
  color: $core-nav-text;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
strong{
  font-weight: bolder;
}

.footer-social-text{
  color: $core-nav-text;
  font-family: 'Lato', sans-serif;
  font-size: 1.5rem;
}

.footer-nav-item-link{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

}

footer{
  min-height: 210px;
  background: $footer-bg-color;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 4px, rgba(255,255,255,.1) 4px, rgba(255,255,255,.1) 5px);




  .copyright{
    font-size: 12px;
    color: #FFFFFF;
  }

  .copyright-row{
    border-top: 2px solid $footer-border;
    min-height: 30px;
  }

  .hurs-wrapper{
    list-style: none;
    padding: 0px !important;
    margin-bottom: 0px !important;
    margin-top: -25px;

    li{
      display: inline-block;
      font-family: 'Cabin', sans-serif;
      font-weight: bold;

      span{
        color: $footer-border;
      }

    }
  }


  .store-info-row{
    padding-top: 10px;
    border-top: 2px solid $footer-border;
    min-height: 140px;
    margin-top: 30px;
    color: #FFFFFF;
    font-family: Freshman;


    .loc-number{
      font-size: 75px;
      color: $footer-loc-num;
      margin-bottom: 0px;

    }

    @media only screen and (max-width: 544px) {
      .loc-number{
        font-size: 28px;
      }
    }

    .loc-city{
      font-size: 30px;
      margin-bottom: 0px;
    }

    .loc-address{
      font-size: 20px;
      letter-spacing: 1px;
      margin-bottom: 0px;
    }

    .loc-by{
      font-size: 20px;
    }

    .loc-dev{
      font-size: 30px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .loc-area{
      font-size:  20px;
      margin-bottom: 0px;
    }

    .loc-cater{
      font-size: 30px;
      margin-bottom: 0px;
    }
  }
}
