#red-border{
  border: 1px solid #ED3337;
}

.jumbotron-deals{
  border-bottom: 4px solid #ED3337 !important;
  background: url("/images/slider/slider_bg_star.png") bottom center no-repeat;
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.save-ink{
  color:  $footer-border;
  font-size: 1.2em;
  text-align: center;
  font-family: Lato;
  font-weight: bold;
}

.deal-title{
  font-size: 1.2em !important;
}
.deal-footer{
  font-family: Lato;
  font-size: 0.85rem
}

.card-deck{
  min-height: 96px !important;


}
.deal-text{
  font-size: 0.85rem !important;
  font-family: Lato;
}

.deal-trems-wrapper{
  min-height: 34px;
  min-width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: Lato;
  font-size: 0.85rem;
  padding-top: 4px;
  text-align: center;
}

.deal-price{
  font-size: 2.25rem;
  line-height: 1;
  letter-spacing: -2px;
  color: #EF3237;
  text-align: right;
  font-family: Lato;
  font-weight: bold;


  .deal-dollar{
    position: relative;
    top: -0.4em;
    margin-right: 2px;
    font-size: 60%;
  }
}

//----------------------------------------------\\
//--------- SCSS for Back of Deal Cards --------\\
//----------------------------------------------\\

.deal-wrapper{
  margin-top: 40px;
  .deal-item-column{
    height: 100%;

  }
  .mobile-hr{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    border: 0 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .border-right{
    border-right: 1.5px solid $footer-bg-color;
  }

  .item-size-heading{
    font-family: City;
    line-height: 1.8rem;
    font-size: 1.8rem;
    letter-spacing: 1px;

  }

  .item-size-small-text{
    font-family: Lato;
    font-size: 0.9em;
    color: $footer-bg-color;
    font-weight: bold;


  }
  .item-size-med-text{
     font-family: Lato;
     font-size: 1em;
     color: $footer-bg-color;
     font-weight: bold;
   }


  .item-plus{
    font-family: City;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: $footer-border;
  }

}



