.item-wrapper-box{
  background: #EEEEEE;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 50px;
  padding-top: 30px;
  padding-bottom: 30px;


  }





