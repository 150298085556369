@font-face {
  font-family: 'Freshman';
  src: url('fonts/Freshman.eot');
  src: local('☺'), url('../fonts/Freshman.woff') format('woff'), url('../fonts/Freshman.ttf') format('truetype'), url('fonts/Freshman.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@import 'https://fonts.googleapis.com/css?family=Cabin:400,500,700,700i';
//@import 'https://fonts.googleapis.com/css?family=Alfa+Slab+One|Baloo+Bhai|Baloo+Tamma|Baloo+Thambi|Changa+One|Fugaz+One';
//@import 'https://fonts.googleapis.com/css?family=Alfa+Slab+One';
@import 'https://fonts.googleapis.com/css?family=Alfa+Slab+One|Candal|Lato:400,400i,700,700i,900,900i|Russo+One';
